import React, { useEffect } from "react";

const Home = () => {
  return (
  <div>
    {/* <h1>Xceptional Learning Meet</h1> */}
  </div>);
};

export default Home;
