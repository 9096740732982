import React, { useState } from 'react';
import './ContentPaginationStyles.css';
const ContentPagination = ({ 
    pageCount,
    handlePageClick,
    currentPage
}) => {

const range = 3;
const pages = Array.from({ length: pageCount }, (_, index) => index + 1);
let pageNumbers = [];

pageNumbers = pages.slice(0, 3);
const start = Math.max(currentPage - range - 1, 2);
const end = Math.min(currentPage + range, pageCount - 1);
pageNumbers = [...pageNumbers, ...pages.slice(start, end + 1)];
pageNumbers = [...pageNumbers, ...pages.slice(-3)];
pageNumbers = Array.from(new Set(pageNumbers)).sort((a, b) => a - b);

let paginationItems = [];
let lastPage = 0;
pageNumbers.forEach(page => {
  if (lastPage !== 0 && page - lastPage > 1) {
    paginationItems.push('  .   .   .   ');
  }
  paginationItems.push(page);
  lastPage = page;
});

return (
  <ul className="pagination">
    {paginationItems.map((item, index) => (
      <li
        key={index}
        className={`pagination-item ${currentPage === item ? 'active' : ''}`}
        onClick={() => typeof item === 'number' ? handlePageClick(item) : null}
      >
        {item}
      </li>
    ))}
  </ul>
);
};

export default ContentPagination;