import React, { useState } from 'react';
import './ContentListStyles.css';
const ContentList = ({ 
    isContentLoading,
    contentList,
    handleItemClick
}) => {
  return (
    <>
    {!isContentLoading?
        contentList.length > 0?
        <ul className="content-list">
            {contentList.map(content => (
            <li key={content.id} onClick={() => handleItemClick(content)} title={content.title}>
                <img src={content.thumbnailUrl || "/images/logo.png"} alt={content.title} />
                <span className="content-title">{content.title}</span>
            </li>
            ))}
        </ul> : <center>{"No content found!"}</center>
    : <center>{"Loading..."}</center>}
    </>
  );
};

export default ContentList;