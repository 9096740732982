import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Room from "./Room";
import Home from "./Home";
const addMetaTags = () => {
  const metaTags = document.createElement('meta');
  metaTags.name = 'viewport';
  metaTags.content = 'width=device-width, initial-scale=1';
  document.head.appendChild(metaTags);
}

const App = () => (
  <>
  {addMetaTags()}
    <Router>
      <Routes>
        <Route path="/xlroom" element={<Room/>} />
        <Route path="/" element={<Home/>} />
      </Routes>
    </Router>
    <Home />
  </>
);

export default App;
