import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import './DisclaimerModalStyles.css';

const DisclaimerModal = ({ handlConsentResponse }) => {
  const [visible, setVisible] = useState(true);

  const handleAccept = () => {
    setVisible(false);
    handlConsentResponse();
  };

  return (
    <Modal
      title="Disclaimer"
      open={visible}
      closable={false}
      maskClosable={false}
      footer={[
        <Button key="ok" type="primary" onClick={handleAccept}>
          Ok
        </Button>,
      ]}
      className='warning-modal'
      method='info'
    >
      <p>
        <b>This call may be recorded for quality assurance.</b>
      </p>
    </Modal>
  );
};

export default DisclaimerModal;
